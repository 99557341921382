.benefitImg {
  width: 279.04px;
  height: 263.38px;

  margin-bottom: 65px;
}

.benefitImgHeading {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  color: #43525e;
}

.benefitImgPara {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #43525e;
}

.advertisementHeading {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #43525e;
}

.advertisementPara {
  /* width: 249px; */
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #43525e;
}