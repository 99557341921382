.sub-preview-title {
  color: #43525e;
  font-size: 20px;
  font-weight: 600;
}
.img-profile {
  height: 50px;
  width: 50px;
  border-radius: 100px;
  float: left;
}
.peron-name {
  font-size: 18px;
  font-weight: 600;
}
.person-designation {
  font-size: 14px;
  font-weight: 400;
  margin-top: -15px;
}
.submission-desc {
  font-size: 14px;
  font-weight: 400;
}
.sub-preview-amount {
  color: #0084ff;
  font-size: 16px;
  font-weight: 600;
}
.sub-preview-approx-ammount {
  color: #98a5af;
  font-size: 14px;
  float: right;
}

.sub-preview-btn {
  width: 136px;
  height: 53px;
  background-color: #0084ff !important;
  outline: none !important;
  border: none !important;
  box-shadow: 0px 8px 8px rgba(203, 203, 203, 0.25) !important;
  border-radius: 10px !important;
}

.sub-details-btn {
  width: 136px;
  height: 53px;
  background-color: #43525e !important;
  outline: none !important;
  border: none !important;
  box-shadow: 0px 8px 8px rgba(203, 203, 203, 0.25) !important;
  border-radius: 10px !important;
}

.one-row-img-container {
  display: inline-block;
}

/* DESKTOP */
@media (min-width: 1281px) {
  .sub-preview-btn {
    margin-top: 65px;
  }
}

/* IPAD PRO */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .sub-preview-btn {
    margin-top: 65px;
  }
}

/* IPAD */

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  .one-row-img {
    height: 90px;
    width: 90px;
  }

  .sub-preview-btn {
    margin-top: 35px;
  }
  .one-row-img-container {
    margin-bottom: 10px;
  }
}

/* IPHONE 6/7/8 */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
  

  .one-row-img {
    height: 100px;
    width: 100px;
  }
  .one-row-img-container {
    margin-bottom: 10px;
  }
}

/* IPHONE 6/7/8 PLUS */
@media only screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
  .submission-amount-container {
    margin-top: -62px;
  }
  .one-row-img {
    height: 110px;
    width: 110px;
  }
  .one-row-img-container {
    margin-bottom: 10px;
  }
}

/* IPHONE X */
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .submission-amount-container {
    margin-top: -62px;
  }

  .one-row-img {
    height: 100px;
    width: 100px;
  }
  .one-row-img-container {
    margin-bottom: 10px;
  }
}

/* PIXEL 2 XL */
@media screen and (device-width: 411px) and (device-height: 823px) and (-webkit-device-pixel-ratio: 3.5) {
  .submission-amount-container {
    margin-top: -65px;
  }
  .one-row-img {
    height: 110px;
    width: 110px;
  }
  .one-row-img-container {
    margin-bottom: 10px;
  }
}

/* SAMSUNG S5 */
@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3) {
  .submission-amount-container {
    margin-top: -62px;
  }
  .one-row-img {
    height: 90px;
    width: 90px;
  }
  .one-row-img-container {
    margin-bottom: 10px;
  }
}
