.posting-banner {
  /* width: 1080px; */
  width: 100%;
  height: auto;
  opacity: 0.8;
  background-image: url("../../../assests/images/CTA.webp");
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgba(0, 132, 255, 0.8);
  padding: 80px;
}
.banner-para {
  /* position: absolute; */
  /* width: 836px; */
  width: auto;

  /* height: 84px; */
  height: auto;

  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;

  color: #ffffff;
}

.button {
  width: 136px;
  height: 46px;
  left: 179px;
  top: 2189px;
  cursor: pointer;
  background: #ffffff;
  border-radius: 100px;
}
.button-text {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  padding-left: 25px;
  padding-top: 13px;
  color: #0084ff;
}

@media (max-width:992px){
  .posting-banner{
    padding: 20px;
    text-align: center;
  }
  .button-text{
    padding-left: 0;
  }
  }