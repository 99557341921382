.services-heading {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}
.services-txt {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  max-width: 610px;
}

@media only screen and (max-device-width: 480px) {
  .services-txt {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    width: 100%;
  }
}
