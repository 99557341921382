.slide-card {
  height: 371px;
  /* width: 292px; */
  width: auto;
  cursor: pointer;
  margin: 0 20px 0 0;
  overflow: hidden;
}

.slide-card-title {
  font-size: 16px !important;
  color: #98a5af;
}

.slide-card-body {
  font-size: 14px;
  color: #43525e;
  padding-bottom: 20px !important;
}


.slide-card-img {
  height: 208px !important;
}

.card-img-top {
  height: 208px !important;
}

.react-multiple-carousel__arrow {
  background-color: #0084ff !important;
}

.arrow {
  background: transparent;
  border: none;
  color: #fff;
  position:absolute;
  top:370px;
}

.right {
  right: 0;
}

.right-10 {
  right: 50px;
}

/* IPHONE 6/7/8 */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
  .react-multi-carousel-track {
    margin-left: 25px;
  }
}

/* IPHONE 6/7/8 PLUS */
@media only screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
  .react-multi-carousel-track {
    margin-left: 50px;
  }
}

/* IPHONE X */
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .react-multi-carousel-track {
    margin-left: 25px;
  }
}

/* PIXEL 2 XL */
@media screen and (device-width: 411px) and (device-height: 823px) and (-webkit-device-pixel-ratio: 3.5) {
}

/* SAMSUNG S5 */
@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3) {
  .react-multi-carousel-track {
    margin-left: 25px;
  }
}
