.searchFieldBtn {
  background-color: #0084ff !important;
  outline: none !important;
  border: none !important;
  border-radius: 10px !important;
  height: 55px !important;
  width: 65px !important;
}

.searchField {
  border-radius: 10px !important;
  background: #ffffff !important;
  border: 1px solid #e6e6e6 !important;
  box-sizing: border-box !important;
  height: 55px !important;

  box-shadow: 0px 8px 8px rgba(203, 203, 203, 0.25) !important;
}

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 3.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.postBtn {
  background-color: #0084ff !important;
  color: #ffffff !important;
  width: auto !important;
  font-size: 14px !important;
  height: 53px !important;
  font-weight: 600 !important;
  border-radius: 10px !important;
  padding: 13px 10px !important;
}
.btnPostTxt {
  margin:auto;
}

@media (max-width: 768px){
  .postReqBox{
    width: 100vw !important;
    background-color: white;
    position: fixed !important;
    bottom: 0;
    z-index: 99;
    padding: 16px 0;
  }
}