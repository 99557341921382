.feed-card {
  border: 1px solid #e6e6e6 !important;
  box-shadow: 0px 8px 8px rgba(203, 203, 203, 0.25) !important;
  border-radius: 10px !important;
  margin-bottom: 20px;
}

.feed-title {
  font-weight: 600;
  font-size: 18px;
  color: #43525e;
  cursor: pointer;
}
.feed-normal {
  font-weight: normal;
  font-size: 14px;
  color: #43525e;
}
.feed-footer {
  background-color: #ffffff !important;
}
.feed-amount {
  color: #0084ff;
  font-weight: 600;
  font-size: 18px;
  /* text-align: right; */
}
.feed-approx-amount {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  /* text-align: right; */
  color: #98a5af;
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .feed-amount {
    text-align: right;
  }
  .feed-approx-amount {
    text-align: right;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .feed-amount {
    text-align: right;
  }
  .feed-approx-amount {
    text-align: right;
  }
}
