#footer {
  background-color: #324259;
  /* height: 179px; */
  padding: 30px;
}
.height-100{
  height: 75%;
}
.width-100{
  width: 75%;
}
.f-icons{
  height: 22px;
  width: 22px;
}
.links {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  /* padding-left: 50px; */
}
.links:hover {
  text-decoration: none;
  color: #fff;
}
/* 
.nav {
  margin-left: 24px; */
  /* width: 132%;
} */

@media (min-width: 1281px) {
  .nav {
    margin-left: -110px;
  }
}

.seprator {
  /* width: 1030px; */
  height: 0px;
  /* left: 55px;
  top: 1498px; */
  /* margin-top: -75px; */

  border: 1px solid #616a73;
}

.footer-para {
  /* margin-top: -51px;

  height: 24px;
  left: 169px;
  top: 2522px; */

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #ffffff;
}
.social-icons {
  display: flex;
  flex-direction: row;
  float: right;
}

@media only screen and (max-device-width: 480px) {
  /* #footer {
    height: 450px;
  } */
  /* .nav {
    margin-left: 24px;
  } */

  .social-icons {
    display: flex;
    flex-direction: row;
    float: left;
    justify-content: center;
    margin-top: 20px;
  }
  .height-100{
    height: 100%;
  }
  .width-100{
    width: 100%;
  }
  /* .footer-logo {
    margin-left: 123px;
  } */
  .links {
    margin-top: 30px;
    padding-left: 0px;
  }
}
